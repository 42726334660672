.meow__headernft {
    display: flex;
    flex-direction: column;
    color: #fff;
    font-family: var(--font-family);
    width: 100%;
    background: black;
  }

  .light-mode .meow__headernft {
    background: #E7E4EF; /* Fundo claro para o modo claro */
    color: #000; /* Texto preto para o modo claro */
  }
  
  .meow__headernft_container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-top: 2rem;
    width: 100%;
    margin-left: 7rem;
  }
  
  .meow__headernft_content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .meow__headernft_content h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 4rem;
    line-height: 75px;
    letter-spacing: 0.02em;
  }
  .meow__headernft_content h2 {
    color: #428eae;
    margin-top: 4rem;

  }
  
  .meow__headernft_content p {
    font-size: 1.2rem;
    line-height: 1.7em;
    margin-top: 2rem;
    margin-bottom: 4rem;
  }
  
  .meow__headernft_buttons {
    display: flex;
    gap: 2rem;
    margin-bottom: 4rem;
  }
  
  .meow__headernft_buttons-button1 button,
  .meow__headernft_buttons-button2 button,
  .meow__headernft_buttons-buttonairdrop button {
    font-family: var(--font-family);
    width: 250px;
    height: 50px;
    color: #fff;
    border: none;
    font-size: 1rem;
    font-weight: 900;
    border-radius: 8px;
  }
  
  .meow__headernft_buttons-button1 button {
    margin-top: 3rem;
    background-color: #428eae;
  }

  .meow__headernft_buttons-buttonairdrop button {
    margin-top: 3rem;
    background-color: #D862FF;
  }
  
  
  
  .meow__headernft-img {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 4rem;
    margin-bottom: 2rem;
  }
  
  .meow__headernft-img img {
    width: 100%;
    height: auto;
  }

  .meow__FirsrNFTcards {
    display: flex;
    margin-left: 0rem;
    margin-right: 12rem;
    align-items: center;
    margin-top: 3rem;
  }
  
  .meow__FirsrNFTcards__container {
    background: #184e6eab;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 320px;
    width: 300px;
    height: 370px;
    display: flex;
    flex-direction: column;
  }
  
  .meow__FirsrNFTcards__background {
    position: relative;
    width: 100%;
    height: 310px; /* Ajuste a altura conforme necessário */
    overflow: hidden;
  }
  
  .background-image_nft {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
  }
  
  
  .meow__FirsrNFTcards__logo {
    margin-top: -30px; /* Ajuste conforme necessário */
    margin-left: 10px;
    z-index: 1;
  }
  
  .meow__FirsrNFTcards__logo img {
    width: 100px; /* Aumentando o tamanho da logo */
    height: auto;
  }
  
  .meow__FirsrNFTcards__text {
    padding: 20px;
    text-align: left; /* Alinhando o texto à esquerda */
    margin-top: -10px; /* Diminuindo a margem superior para reduzir o espaço entre o logo e o h1 */
  }
  
  
  .meow__FirsrNFTcards__text h1 {
    margin-bottom: 10px;
    color: #ffff;
    font-family: var(--font-family);
    font-size: 1.5rem;
  }
  
  .meow__FirsrNFTcards__text p {
    margin-bottom: 20px;
    color: #ffff;
    font-family: var(--font-family);
    
  }
  
  .play-button {
    width: 100%;
    background-color: #428EAE;
    color: white;
    font-family: var(--font-family);
    font-size: 1rem;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  .meow__FirsrNFTcards__progress {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem auto;
    gap: 1rem;
  }
  
/* Estilo do círculo de progresso */
.progress-circle {
    position: relative;
    width: 80px; /* Ajuste conforme necessário */
    height: 80px; /* Ajuste conforme necessário */
    border-radius: 50%;
    border: 4px solid transparent; /* Inicialmente transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background-size: cover; /* Ajusta a imagem para cobrir o círculo */
    background-position: center;
    background-repeat: no-repeat;
    transition: border-color 0.3s; /* Transição suave da cor da borda */
  }
  
  /* Borda ativa (verde) */
  .progress-circle.active {
    border-color: #1f263b; /* Verde para o círculo ativo */
  }
  
  /* Borda inativa (branca) */
  .progress-circle:not(.active) {
    border-color: #2d4053; /* Branco para círculos inativos */
  }
  
  /* Animação do progresso */
  .progress-bar {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    clip: rect(0px, 80px, 80px, 40px);
    position: absolute;
    transform: rotate(0deg);
    transition: transform 5s linear; /* Duração da animação */
    z-index: 2;
    border: 4px solid transparent; /* Inicialmente transparente */
  }
  
  /* Remova a animação de rotação anterior */
.progress-bar.progress-active {
    border-color: #ffff; /* Cor verde para a borda ativa */
    animation: fillAnimation 30s linear forwards; /* Animação de preenchimento */
    clip: rect(0px, 40px, 80px, 0px); /* Inicializa a barra de progresso */
  }
  
  /* Defina a animação fillAnimation */
@keyframes fillAnimation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(15deg);
    }
    20% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(35deg);
    }
    35% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(75deg);
    }
    55% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(100deg);
    }
    65% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(150deg);
    }
    75% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(180deg);
    }
    85% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(230deg);
    }
    95% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(330deg);
    }
    100% {
      clip: rect(0px, 80px, 80px, 0px);
      transform: rotate(360deg);
    }
  }
  

  
  
  /*------------------1025px-----------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  --------------------------------------------------- */
  @media screen and (max-width: 1025px) {
  
    .meow__headernft_content h1 {
      font-family: var(--font-family);
      font-weight: 800;
      font-size: 2.5rem;
      line-height: 55px;
      letter-spacing: 0.02em;
      width: 600px;
    }

    .meow__headernft_buttons {
      display: flex;
      gap: 2rem;
      margin-bottom: 4rem;
    }
    
    .meow__headernft_buttons-button1 button,
    .meow__headernft_buttons-button2 button,
    .meow__headernft_buttons-buttonairdrop button {
      font-family: var(--font-family);
      width: 200px;
      height: 50px;
      color: #fff;
      border: none;
      font-size: 1rem;
      font-weight: 900;
      border-radius: 8px;
    }

    .meow__FirsrNFTcards {
      display: flex;
      margin-left: -2rem;
      margin-right: 12rem;
      align-items: center;
      margin-top: 3rem;
    }

    .meow__FirsrNFTcards__container {
      background: #184e6eab;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 320px;
      width: 300px;
      height: 370px;
      display: flex;
      flex-direction: column;
    }
    
  
  }
  
  
  /*------------------800px-----------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  --------------------------------------------------- */
  @media screen and (max-width: 850px) {

    .meow__headernft_container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 2rem;
      width: 100%;
      margin-left: 4rem;
    }
    .meow__headernft_content h1 {
      font-family: var(--font-family);
      font-weight: 800;
      font-size: 2rem;
      line-height: 35px;
      letter-spacing: 0.02em;
      width: 600px;
    }

    .meow__headernft_buttons {
      display: flex;
      gap: 1rem;
      margin-bottom: 4rem;
    }
    
    .meow__headernft_buttons-button1 button,
    .meow__headernft_buttons-button2 button,
    .meow__headernft_buttons-buttonairdrop button {
      font-family: var(--font-family);
      width: 165px;
      height: 50px;
      color: #fff;
      border: none;
      font-size: 1rem;
      font-weight: 900;
      border-radius: 8px;
    }

    .meow__FirsrNFTcards {
      display: flex;
      margin-left: -11rem;
      margin-right: 12rem;
      align-items: center;
      margin-top: 3rem;
    }

    .meow__FirsrNFTcards__container {
      background: #184e6eab;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 320px;
      width: 250px;
      height: 300px;
      display: flex;
      flex-direction: column;
    }
  }
  
  /*------------------500px-----------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  -----------------------------------------------------
  --------------------------------------------------- */
  @media screen and (max-width: 500px) {
    .meow__headernft_container {
      flex-direction: column;
      margin-left: 8rem;
    }
  
    .meow__headernft_content h1 {
      font-family: var(--font-family);
      font-weight: 800;
      font-size: 1.8rem;
      line-height: 35px;
      letter-spacing: 0.02em;
    }
    .meow__headernft_content h2 {
      color: #428eae;
      margin-top: 4rem;
  
    }
    
  

    .meow__FirsrNFTcards {
      display: flex;
      margin-left: -3rem;
      align-items: center;
      margin-top: 0rem;
    }
    .meow__FirsrNFTcards__container {
      background: #184e6eab;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 330px;
      width: 330x;
      height: 330px;
      display: flex;
      flex-direction: column;
    }
    
    
  
    
  }

  @media screen and (max-width: 321px) {
    .meow__headernft_container {
      flex-direction: column;
      margin-left: 10rem;
    }
  
    .meow__headernft_content h1 {
      font-family: var(--font-family);
      font-weight: 800;
      font-size: 1.4rem;
      line-height: 20px;
      letter-spacing: 0.02em;
    }
    .meow__headernft_content h2 {
      color: #428eae;
      margin-top: 4rem;
  
    }
    
  

    .meow__FirsrNFTcards {
      display: flex;
      margin-left: -8rem;
      align-items: center;
      margin-top: 0rem;
    }
    .meow__FirsrNFTcards__container {
      background: #184e6eab;
      border-radius: 20px;
      overflow: hidden;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      max-width: 330px;
      width: 330x;
      height: 330px;
      display: flex;
      flex-direction: column;
    }

    .meow__headernft_buttons {
      display: flex;
      gap: 1rem;
      margin-bottom: 4rem;
    }
    
    .meow__headernft_buttons-button1 button,
    .meow__headernft_buttons-button2 button,
    .meow__headernft_buttons-buttonairdrop button {
      font-family: var(--font-family);
      width: 135px;
      height: 50px;
      color: #fff;
      border: none;
      font-size: 0.9rem;
      font-weight: 900;
      border-radius: 8px;
    }
  }

  
  
  
  