.popup {
    position: fixed;
    top: 10%;
    right: 10%;
    background-color: white;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 1000;
  }
  
  .popup.success {
    border: 2px solid green;
    color: green;
  }
  
  .popup.error {
    border: 2px solid red;
    color: red;
  }
  
  .popup svg {
    font-size: 24px;
  }
  
  .popup button {
    background: none;
    border: none;
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    color: inherit;
  }
  