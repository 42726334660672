/* nftbenefits.css */

/* Outras classes existentes */

.meow__nft_benefits_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centraliza o conteúdo dentro do wrapper */
}

.meow__nft_benefits {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-left: 2rem;
  color: #fff;
  justify-content: center; /* Centraliza os cards */
  margin-top: 5rem;
}


.meow__nft_benefits__container {
  background: #21B3A0;
  border-radius: 55px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 339px;
  margin: 10px;
  height: 242px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 20px 20px 20px;
}

.meow__nft_benefits_textInside {
  text-align: center;
  font-family: var(--font-family);
  width: 100%;
  margin-top: 2.5rem;
}

.meow__nft_benefits_title {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  text-align: center;
}

.benefit-icon {
  font-size: 2rem;
  color: black;
}

.meow__nft_benefits_textInside h1 {
  color: #fff;
  font-size: 1.5rem;
  margin-top: 0;
}

.meow__nft_benefits_textInside p {
  margin-top: 25px;
  font-size: 1.2rem;
}

/* Estilo para o botão "See All Benefits" */
.meow__nft_benefits_button_wrapper {
  margin-top: 20px; /* Espaçamento acima do botão */
}

.see-all-benefits-button {
    padding: 10px 20px;
    background-color: #D862FF;
    color: #ffff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
    margin-bottom: rem;
}

.see-all-benefits-button:hover {
  background-color: #6e4b8b;
}

/* Media Query para dispositivos móveis */
@media screen and (max-width: 800px) {
    .meow__nft_benefits__container {
        background: #21B3A0;
        border-radius: 55px;
        overflow: hidden;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        width: 315px;
        margin: 10px;
        height: 242px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 20px 20px 20px;
      }
}

/* Media Query para dispositivos móveis */
@media screen and (max-width: 500px) {
  .meow__nft_benefits {
    margin-left: 2.2rem;
  }
}

@media screen and (max-width: 376px) {
  .meow__nft_benefits {
    margin-left: 1.25rem;
  }

  .meow__nft_benefits__container {
    width: 320px;
  }
}

@media screen and (max-width: 321px) {
  .meow__nft_benefits {
    margin-left: 0.5rem;
  }

  .meow__nft_benefits__container {
    width: 320px;
  }

  .benefit-icon {
    font-size: 2rem;
    color: black;
  }

  .meow__nft_benefits_textInside h1 {
    color: #fff;
    font-size: 1.25rem;
    margin-top: 0;
  }

  .meow__nft_benefits_textInside p {
    margin-top: 25px;
    font-size: 1rem;
  }
}
