.meow_nfts_cards {
    display: flex;
    flex-wrap: wrap; /* Permite que os cards se movam para a próxima linha se não houver espaço */
    gap: 20px; /* Espaçamento entre os cards */
    justify-content: flex-start; /* Alinha os cards ao início do container */
    margin-left: 5rem;
  }
  
  .meow__Nft_cards__container {
    background: #101239;
    border-radius: 20px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 300px; /* Largura fixa para os cards */
    margin: 10px; /* Margem para espaçamento entre os cards */
    height: 430px;
    display: flex;
    flex-direction: column;
  }

  .light-mode .meow_nfts_cards  {
    background: #E7E4EF; /* Fundo claro para o modo claro */
    color: #000; /* Texto preto para o modo claro */
  }
  
  .meowl__nft_intro_text {
    text-align: center;
    margin-bottom: 3rem; /* Espaçamento entre o texto e os cards */
    margin-top: 5rem;
  }
  
  .meowl__nft_intro_text h1 {
    color: #ffff;
    font-family: var(--font-family);
    font-size: 3rem;
  }
  
  .meowl__nft_intro_text h3 {
    color: #D862FF;
    font-family: var(--font-family);
    font-size: 1.5rem;
  }
  
  .meowl__nft_cards_textInside {
    display: flex;
    align-items: center; /* Alinha itens ao centro verticalmente */
    gap: 8px; /* Ajuste conforme necessário para o espaçamento desejado */
  }
  
  .meowl__nft_cards_textInside h3 {
    color: #ffff;
    font-family: var(--font-family);
    margin-top: 0.5rem; /* Ajuste de margem para alinhar corretamente */
  }
  
  .profile-image-container {
    width: 40px; /* Ajuste o tamanho conforme necessário */
    height: 40px; /* Ajuste o tamanho conforme necessário */
    border-radius: 50%; /* Faz a imagem ficar circular */
    overflow: hidden; /* Garante que a imagem se ajuste ao container */
    margin-top: 1rem;
    margin-left: 1rem;
  }
  
  .profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Garante que a imagem cubra todo o container sem distorcer */
  }
  
  .meow__Nft_cards__background {
    position: relative;
    width: 100%;
    height: auto;
    margin: 0.3rem; /* Margem no topo e bottom */
    margin-top: 0.7rem;
    margin-bottom: 0rem;
    overflow: hidden;
    flex-grow: 1;
  }
  
  .background1-image {
    width: calc(100% - 0.6rem); /* Ajuste de largura para incluir margem lateral */
    height: 100%;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 20px;
  }
  
  .meow__Nft_cards__text_description {
    padding: 20px;
    text-align: left; /* Alinhando o texto à esquerda */
    margin-top: -10px; /* Diminuindo a margem superior para reduzir o espaço entre o logo e o h1 */
  }
  
  .meow__Nft_cards__text_description h1 {
    color: #ffff;
    font-family: var(--font-family);
    font-size: 1.2rem;
  }
  
  .meow__Nft_cards__text_description h2 {
    font-size: 1rem;
    margin-bottom: -0.5rem;
    color: #ffff;
  }
  
  /* Responsividade para telas menores */
  @media screen and (max-width: 500px) {
  
    .meow_nfts_cards {
        display: flex;
        flex-wrap: wrap; /* Permite que os cards se movam para a próxima linha se não houver espaço */
        gap: 20px; /* Espaçamento entre os cards */
        justify-content: flex-start; /* Alinha os cards ao início do container */
        margin-left: 3.5rem;
      }
  }
  
  @media screen and (max-width: 376px) {
    .meow_nfts_cards {
        display: flex;
        flex-wrap: wrap; /* Permite que os cards se movam para a próxima linha se não houver espaço */
        gap: 20px; /* Espaçamento entre os cards */
        justify-content: flex-start; /* Alinha os cards ao início do container */
        margin-left: 2.2rem;
      }
  }
  
  @media screen and (max-width: 321px) {
    .meow_nfts_cards {
        display: flex;
        flex-wrap: wrap; /* Permite que os cards se movam para a próxima linha se não houver espaço */
        gap: 20px; /* Espaçamento entre os cards */
        justify-content: flex-start; /* Alinha os cards ao início do container */
        margin-left: 0.2rem;
      }
  }



  