@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Numans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Numans&family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');

/* Estilos base */
.meow_cta {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin-top: 5rem;
}

.light-mode .meow_cta_container h1,
.light-mode .meow_cta__input input::placeholder,
.light-mode .meow_cta__input input,
.light-mode .meow_cta__input{
    color: rgb(0, 0, 0);
  }

.meow_cta_container {
    max-width: 800px;
    width: 100%;
    text-align: center;
    margin-top: 5rem;
}

.meow_cta_container h1 {
    color: #fff;
    font-family: "Numans", sans-serif;
    font-weight: 100;
    font-style: normal;
    margin-bottom: 3rem;
}

.meow_cta__input {
    display: flex;
    align-items: center;
}

.meow_cta__input input {
    flex: 1.5; /* Ajusta a flexibilidade para diminuir o input */
    background: transparent;
    width: 50%;
    min-height: 40px; /* Ajuste a altura conforme necessário */
    font-family: "Nunito Sans", sans-serif;
    font-size: 16px;
    line-height: 27px;
    outline: none;
    border: 2px solid transparent;
    padding: 0 1rem;
    border-radius: 5px 0px 0px 5px;
    color: #fff;
    padding-left: 2.5rem; /* Espaço para o ícone de e-mail */
    box-sizing: border-box; /* Garante que o padding não aumente a largura do input */
}

.meow_cta__input input::placeholder {
    color: white;
}

.meow_cta__input_email {
    width: 1.5rem;
    height: 2rem;
    margin-right: 1rem; /* Espaço entre o input e o ícone */
}

.meow_cta__input button {
    width: 143px;
    height: 38px;
    margin-left: -5px; /* Reduzir o espaçamento entre o input e o botão */
    font-family: "Nunito Sans", sans-serif;
    background-color: #686DF1;
    color: #fff;
    border-radius: 15px; /* Aplicando borda arredondada uniformemente */
    display: flex;
    align-items: center;
    justify-content: center;
}

.meow_cta_arrow_icon {
    margin-right: 5px; /* Ajuste fino do ícone */
}

.meow_cta_text {
    font-family: "Nunito Sans",
}

/* Media Queries para telas menores que 500px */
@media screen and (max-width: 800px) {
    
    .meow_cta_container {
        max-width: 800px;
        width: 100%;
        text-align: center;
        margin-top: 5rem;
        margin-left: 5rem;

    }
    
    
    .meow_cta__input input {
        flex: 1.5; /* Ajusta a flexibilidade para diminuir o input */
        background: transparent;
        width: 50%;
        min-height: 40px; /* Ajuste a altura conforme necessário */
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        line-height: 27px;
        outline: none;
        border: 2px solid transparent;
        padding: 0 1rem;
        border-radius: 5px 0px 0px 5px;
        color: #fff;
        padding-left: 2rem; /* Espaço para o ícone de e-mail */
        box-sizing: border-box; /* Garante que o padding não aumente a largura do input */
    }
    
    .meow_cta__input input::placeholder {
        color: white;
    }
    
    .meow_cta__input_email {
        width: 1.5rem;
        margin-right: 0.5rem; /* Espaço entre o input e o ícone */
    }
    
    .meow_cta__input button {
        width: 143px;
        height: 38px;
        
        font-family: "Nunito Sans", sans-serif;
        background-color: #686DF1;
        color: #fff;
        border-radius: 15px; /* Aplicando borda arredondada uniformemente */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 45px;
    }
    
    


}

/* Media Queries para telas menores que 500px */
@media screen and (max-width: 500px) {
    
    .meow_cta_container {
        max-width: 800px;
        width: 100%;
        text-align: center;
        margin-top: 5rem;
        margin-left: 1rem;

    }
    
    
    .meow_cta__input input {
        flex: 1.5; /* Ajusta a flexibilidade para diminuir o input */
        background: transparent;
        width: 50%;
        min-height: 40px; /* Ajuste a altura conforme necessário */
        font-family: "Nunito Sans", sans-serif;
        font-size: 16px;
        line-height: 27px;
        outline: none;
        border: 2px solid transparent;
        padding: 0 1rem;
        border-radius: 5px 0px 0px 5px;
        color: #fff;
        padding-left: 0.2rem; /* Espaço para o ícone de e-mail */
        box-sizing: border-box; /* Garante que o padding não aumente a largura do input */
    }
    
    .meow_cta__input button {
        width: 90px;
        height: 38px;
        
        font-family: "Nunito Sans", sans-serif;
        background-color: #686DF1;
        color: #fff;
        border-radius: 15px; /* Aplicando borda arredondada uniformemente */
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 2px;
    }
    
    


}