.meow__cards {
    width: 400px;
    height: 500px;
    background: linear-gradient(90deg, rgba(86, 78, 114, 0.404) 0%,
    rgba(47, 24, 110, 0.966) 50%, rgba(43, 9, 138, 0.562) 100%);;
    padding: 20px;
    color: #ffff;
    border-radius: #ffff;
    font-family: var(--font-family);
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ffffff35;
    margin-top: 6rem;
    margin-left: 5rem;
    display: flex;
    flex-direction: column;
    
    justify-content: space-between;


}   


.meow__cards_logo_button {
   width: 100%;
   display: flex; 
   justify-content: space-between;
   
}
.meow__cards_logo_button img {
    margin-left: -5rem;

}


.meow__cards_logo_button button {
    
    font-family: var(--font-family);
    width: 200px;
    height: 31px;
    background-color: rgba(255, 255, 255, 0.103);
    color: rgba(255, 255, 255, 0.774);
    border: none;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    
}


.meow__cards_text {
    padding: 9px;
    justify-content: space-between;
}

.meow__cards_text h1 {
    font-family: var(--font-family); 
    font-size: 1.4rem;
}

.meow__cards_text_icons {
    display: flex;
    gap: 10px;
    color: rgba(255, 255, 255, 0.651);
    margin-top: 0.5rem;
}

.meow__cards_content {
    padding: 9px;
    justify-content: space-between;
}
.meow__cards_content h2 {
    font-family: var(--font-family); 
    font-size: 0.95rem;
}

.meow__cards_content h3 {
    font-size: 0.75rem;
    margin-bottom: 1.4px;
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.534);
}

.meow__cards_content p {
    font-size: 0.75rem;
    
}

.meow__cards_seedetails button {
    font-family: var(--font-family);
    width: 100%;
    height: 31px;
    margin-left: 0.5rem;
    background-color: #D862FF;
    color: rgb(255, 255, 255);
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;

}

/* ------------max 500px -----------------------------------------
----------------------------------------------------------------
-------------------------------------------------------------------
-------------------------------------------------------------------
----------------------------------------------------------------*/
@media screen and (max-width: 500px) {
    .meow__cards {
        width: 83%;
        height: 500px;
        background: linear-gradient(90deg, rgba(86, 78, 114, 0.404) 0%,
        rgba(47, 24, 110, 0.966) 50%, rgba(43, 9, 138, 0.562) 100%);;
        padding: 20px;
        color: #ffff;
        border-radius: #ffff;
        font-family: var(--font-family);
        border-radius: 10px;
        box-shadow: 5px 5px 10px #ffffff35;
        margin-top: 6rem;
        margin-left: 2.2rem;
        display: flex;
        flex-direction: column;
        
        justify-content: space-between;
    
    
    } 

}

/* ------------max 426px -----------------------------------------
----------------------------------------------------------------
-------------------------------------------------------------------
-------------------------------------------------------------------
----------------------------------------------------------------*/
@media screen and (max-width: 426px) {
    .meow__cards {
        width: 83%;
        height: 500px;
        background: linear-gradient(90deg, rgba(86, 78, 114, 0.404) 0%,
        rgba(47, 24, 110, 0.966) 50%, rgba(43, 9, 138, 0.562) 100%);;
        padding: 20px;
        color: #ffff;
        border-radius: #ffff;
        font-family: var(--font-family);
        border-radius: 10px;
        box-shadow: 5px 5px 10px #ffffff35;
        margin-top: 6rem;
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        
        justify-content: space-between;
    
    
    } 

}

/* ------------max 376px -----------------------------------------
----------------------------------------------------------------
-------------------------------------------------------------------
-------------------------------------------------------------------
----------------------------------------------------------------*/
@media screen and (max-width: 376px) {
    .meow__cards {
        width: 83%;
        height: 530px;
        background: linear-gradient(90deg, rgba(86, 78, 114, 0.404) 0%,
        rgba(47, 24, 110, 0.966) 50%, rgba(43, 9, 138, 0.562) 100%);;
        padding: 20px;
        color: #ffff;
        border-radius: #ffff;
        font-family: var(--font-family);
        border-radius: 10px;
        box-shadow: 5px 5px 10px #ffffff35;
        margin-top: 6rem;
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        
        justify-content: space-between;
    
    
    } 

}
/* ------------max 376px -----------------------------------------
----------------------------------------------------------------
-------------------------------------------------------------------
-------------------------------------------------------------------
----------------------------------------------------------------*/
@media screen and (max-width: 326px) {
    .meow__cards {
        width: 83%;
        height: 530px;
        background: linear-gradient(90deg, rgba(86, 78, 114, 0.404) 0%,
        rgba(47, 24, 110, 0.966) 50%, rgba(43, 9, 138, 0.562) 100%);;
        padding: 20px;
        color: #ffff;
        border-radius: #ffff;
        font-family: var(--font-family);
        border-radius: 10px;
        box-shadow: 5px 5px 10px #ffffff35;
        margin-top: 5rem;
        margin-left: 2rem;
        display: flex;
        flex-direction: column;
        
        justify-content: space-between;
    
    
    } 

    .meow__cards_logo_button button {
    
        font-family: var(--font-family);
        width: 250px;
        height: 45px;
        background-color: rgba(255, 255, 255, 0.103);
        color: rgba(255, 255, 255, 0.774);
        border: none;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
        border-top-left-radius: 15px;
        border-bottom-left-radius: 15px;
        margin-left: -3.5rem;
        
    }

}