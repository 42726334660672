@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

:root {
  --font-family: 'Inter', sans-serif;

  --gradient-text: -webkit-linear-gradient(right, #6E56CF 70%, #F9D1FF 100% );
  --gradient-bar: linear-gradient(103.22deg, #AE67FA -13.86%, #F49867 99.55%);
  
  --color-bg: #0A0118;
  --color-footer : #031B34;
  --color-blog: #042c54;
  --color-text: #81AFDD;
  --color-subtext: #FF8A71;
}

.lightmode {
  --gradient-text: -webkit-linear-gradient(right, #6E56CF 60%, #53025F 100%);
 
}
button:hover {
  transform: scale(0.95);
  transition: all 0.2s;
} 
button:hover {
  background-color: rgba(0, 0, 255, 0.164);
  color:white;
}
