/* src/components/LoadingPopup.css */

.denied-popup {
    width: 17%;
    height: 250px;
    background: linear-gradient(90deg, rgba(86, 78, 114, 0.404) 0%, rgba(47, 24, 110, 0.966) 50%, rgba(43, 9, 138, 0.562) 100%);
    padding: 20px;
    color: #ffff;
    border-radius: 10px;
    box-shadow: 5px 5px 10px #ffffff35;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .denied-popup-content img {
    width: 100%;
    justify-content: center;
    align-items: center;

  }
  
  .denied-popup-content p {
    width: 100%;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family); 
    margin-left: 1.2rem;
    margin-bottom: 2rem;
  }

  @media screen and (max-width: 1025px) {
    .denied-popup {
      width: 30%;
      height: 300px;
    
    }
  }
  
  @media screen and (max-width: 500px) {
    .denied-popup {
      width: 60%;
      height: 300px;
    
    }
  }
  
  
  