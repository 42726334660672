.info-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fundo escuro semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ajuste conforme necessário para garantir que fique acima de outros elementos */
  }
  
  .info-popup-content {
    background-color: #2B098A; /* Azul escuro */
    color: #fff; /* Texto branco */
    padding: 20px;
    max-width: 80%;
    border-radius: 8px;
    text-align: left;
    overflow-y: auto; /* Adiciona scroll se necessário */
    max-height: 80%; /* Altura máxima do popup */
  }
  
  .info-popup-content h2 {
    font-size: 1.5rem;
    margin-bottom: 15px;
  }
  
  .info-popup-content p {
    line-height: 1.6;
    margin-bottom: 10px;
  }
  
  .info-popup-content ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  .info-popup-content li {
    margin-bottom: 8px;
  }

  .info-popup button {
    background-color: #8a2be2; /* Cor roxa */
    color: #fff; /* Texto branco */
    border: none;
    margin-top: 2rem;
    padding: 10px 20px; /* Ajuste o padding conforme necessário */
    font-size: 1rem; /* Tamanho da fonte */
    border-radius: 8px; /* Border radius */
    cursor: pointer;
    transition: background-color 0.3s ease; /* Efeito de transição */
  }
  
  .info-popup button:hover {
    background-color: #6a1c9a; /* Cor roxa mais escura ao passar o mouse */
  }
  