/* src/components/cards_games.css */

.meow__cardsOptions {
    display: flex;
    margin-left: 10rem;
    align-items: center;
    margin-top: 3rem;
  }
  
  .meow__cardsOptions__container {
    background: #184e6eab;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 320px;
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
  }
  
  .meow__cardsOptions__background {
    position: relative;
    width: 100%;
    height: 200px; /* Ajuste a altura conforme necessário */
    overflow: hidden;
  }
  
  .background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .status-button {
    font-family: var(--font-family);
    position: absolute;
    width: 110px;
    height: 30px;
    top: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.103);
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;
  }
  
  .meow__cardsOptions__logo {
    margin-top: -30px; /* Ajuste conforme necessário */
    margin-left: 10px;
    z-index: 1;
  }
  
  .meow__cardsOptions__logo img {
    width: 100px; /* Aumentando o tamanho da logo */
    height: auto;
  }
  
  .meow__cardsOptions__text {
    padding: 20px;
    text-align: left; /* Alinhando o texto à esquerda */
    margin-top: -10px; /* Diminuindo a margem superior para reduzir o espaço entre o logo e o h1 */
  }
  
  
  .meow__cardsOptions__text h1 {
    margin-bottom: 10px;
    color: #ffff;
    font-family: var(--font-family);
    font-size: 1.5rem;
  }
  
  .meow__cardsOptions__text p {
    margin-bottom: 20px;
    color: #ffff;
    font-family: var(--font-family);
    
  }
  
  .play-button {
    width: 100%;
    background-color: #428EAE;
    color: white;
    font-family: var(--font-family);
    font-size: 1rem;
    border: none;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
  }

  /* tela até 500 px ---------------------
-----------------------------------------
-----------------------------------------
-----------------------------------------
--------------------------------------*/


@media screen and (max-width: 500px) {

  .meow__cardsOptions {
    display: flex;
    margin-left: 4rem;
    align-items: center;
    margin-top: 3rem;
  }
 

}

/* tela até 376 px ---------------------
-----------------------------------------
-----------------------------------------
-----------------------------------------
--------------------------------------*/


@media screen and (max-width: 376px) {

  .meow__cardsOptions {
    display: flex;
    margin-left: 3rem;
    align-items: center;
    margin-top: 3rem;
  }

}

/* tela até 376 px ---------------------
-----------------------------------------
-----------------------------------------
-----------------------------------------
--------------------------------------*/


@media screen and (max-width: 321px) {

  .meow__cardsOptions {
    display: flex;
    margin-left: 0.8rem;
    align-items: center;
    margin-top: 3rem;
  }

}
  