.meowl_contentProject {
    font-family: var(--font-family);
    display: flex;
    flex-direction: column;
}

.meowl_contentProject_container {
    margin-top: 5rem;
    margin-left: 5rem;
}

.meowl_contentProject_title {
    font-size: 0.9rem;
}

.meowl_contentProject_content {
    margin-top: 1.75rem;
    display: flex;
}

.meowl_contentProject_logo img {
    width: auto;
    height: 100px;
}

.meowl_contentProject_maincontent {
    margin-top: 0.4rem;
    margin-left: 1rem;
    
}

.meowl_contentProject_firstcontent {
    display: flex;
    gap: 35rem;

}
.meowl_contentProject_clock_box {
    margin-top: 0.5rem;
    width: 300px;
    height: 30px;
    background-color: #d862ffb4; 
    border-radius: 8px;
    
}

.light-mode  .meowl_contentProject_clock_box{
    background-color: #EDB7FF;
}
.meowl_contentProject_clock_content {
    display: flex;
    gap: 0.8rem;
    font-size: 0.7rem;
    font-weight: bold;
}
.meowl_contentProject_clock_content p {
    margin-top: 0.4rem;
    margin-left: 0.5rem;
}
.text-box-container_cont {
    display: flex;
    gap: 10px;
    margin-bottom: 0.5rem;
    flex-wrap: wrap; /* Permite que as caixas quebrem linha se necessário */
  }
  
  .text-box_cont {
    
    padding: 0.5rem 1rem;
    background-color: #D862FF;
    color: #000000;
    border-radius: 7px;
    font-weight: bold;
    font-size: 0.6rem;
    min-width: 20px; /* Tamanho mínimo da caixa */
    text-align: center; /* Centraliza o texto dentro da caixa */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
  }
  
  .text-box_cont h5 {
    margin: 0; /* Remover margens padrão */
    line-height: 1; /* Ajustar a altura da linha */
    padding-bottom: 2px; /* Adicionar margem interna inferior */
  }
  
  .text-box_cont.box1 {
    background-color: #B4C287; /* Cor da primeira caixa */
  }
  
  .text-box_cont.box2 {
    background-color: #92A8B3; /* Cor da segunda caixa */
  }
  
  .text-box_cont.box3 {
    background-color: rgb(23, 171, 235); /* Cor da terceira caixa */
  }

  .text-box-container_cont p {
    line-height: 1.5em;
    margin-top: 1rem;
    max-width: 90%;
  }

  .meowl_contentProject_media {
    display: flex;
    gap: 13px;
    color: rgba(255, 255, 255, 0.651);
    margin-top: 0.5rem;
    font-size: 1.5rem;
  }
  
  .light-mode .meowl_contentProject_media {
    color: #000000;
  }

  

  
@media screen and (max-width: 1025px) {

    .meowl_contentProject_firstcontent {
        gap: 13rem;
    
    }
    .text-box-container_cont p {
        margin-top: 1rem;
        max-width: 95%;
      }
}

@media screen and (max-width: 950px) {

    .meowl_contentProject_firstcontent {
        gap: 8.5rem;
    
    }
   .meowl_contentProject_firstcontent h1{
    font-size: 1.5rem;
   }
   .meowl_contentProject_firstcontent h2{
    font-size: 1rem;
   }

   .meowl_contentProject_clock_box {
    margin-top: 0.5rem;
    width: 200px;
    height: 25px;
    border-radius: 8px;
    
    }
    .meowl_contentProject_clock_content {
        display: flex;
        gap: 0.6rem;
        font-size: 0.45rem;
        font-weight: bold;
    }
    .meowl_contentProject_clock_content p {
        margin-top: 0.4rem;
        margin-left: 0.5rem;
    }
   
    
}

@media screen and (max-width: 600px) {
    .meowl_contentProject_container {
        margin-top: 5rem;
        margin-left: 2rem;
    }
    .meowl_contentProject_content {
        margin-top: 1.75rem;
        display: flex;
        flex-direction: column;
    }
    .meowl_contentProject_firstcontent {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    
    }
    .text-box-container_cont {
        margin-top: 1rem;
        display: flex;
        gap: 10px;
        margin-bottom: 0.5rem;
        flex-wrap: wrap; /* Permite que as caixas quebrem linha se necessário */
      }
      .meowl_contentProject_maincontent {
       
        margin-left: 0rem;
        
    }

    .meowl_contentProject_logo img {
        margin-left: 7.5rem;
        width: auto;
        height: 100px;
        margin-top: 1.4rem;
        margin-bottom: 1.4rem;
    }
    .meowl_contentProject_clock_box {
        margin-top: 0.5rem;
        width: 270px;
        height: 25px;
        border-radius: 8px;
        
        }
        .meowl_contentProject_clock_content {
            display: flex;
            gap: 0.6rem;
            font-size: 0.65rem;
            font-weight: bold;
        }

}

@media screen and (max-width: 390px) {
    .meowl_contentProject_logo img {
        margin-left: 6.2rem;
        
    }

}

@media screen and (max-width: 321px) {
    .meowl_contentProject_logo img {
        margin-left: 4.8rem;
        
    }

    .meowl_contentProject_clock_box {
        margin-top: 0.5rem;
        width: 270px;
        height: 25px;
        border-radius: 8px;
        
        }
        .meowl_contentProject_clock_content {
            display: flex;
            gap: 0.6rem;
            font-size: 0.65rem;
            font-weight: bold;
        }

}


  