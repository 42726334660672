* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}

body {
  background-color: black;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
  width: 100%;
}

html {
  margin: 0;
  padding: 0;
  width: 100%;
  overflow-x: hidden; /* Previne o scroll horizontal */
}

a {
  color: unset;
  text-decoration: none;
}

.gradient_bg {
  /* ff 3.6+ */
  background: linear-gradient(90deg, rgba(113, 61, 255, 0.055) 0%, rgba(113, 61, 255, 0.171) 50%, rgba(113, 61, 255, 0.075) 100%);
}



  
  

.gradient_text {
    background:var(--gradient-text);
    background-clip: text;
    -webkit-background-clop: text;
    -webkit-text-fill-color: transparent;

}


.section_padding {
  padding: 4rem 6rem;
}

.section_margin {
  margin: 4rem 6rem;

}

.scale-up-center {
	-webkit-animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
	        animation: scale-up-center 0.4s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}


/* ----------------------------------------------
 * Generated by Animista on 2024-5-15 14:39:1
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation scale-up-center
 * ----------------------------------------
 */
 @-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.5);
            transform: scale(0.5);
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}


@media screen and (max-width: 700px){
  .section_padding {
    padding: 4rem;


  }
  .section_margin {
    margin: 4rem;
    
  }

  .teste {
    position: absolute;
    top: 50px;
    left: 100px;
    

  }
  .teste img {
    opacity: 1; /* Define a transparência, ajuste conforme necessário */
  }


.overlay-image {
  position: absolute; /* Posiciona de forma absoluta em relação ao .gradient_bg */
  top: 40%; /* Ajuste conforme necessário */
  left: 50%; /* Ajuste conforme necessário */
  transform: translate(-50%, -50%); /* Centraliza a imagem */
}

.overlay-image img {
  width: 1000px;
  opacity: 10; /* Define a transparência, ajuste conforme necessário */
}


}


@media screen and (max-width: 550px){
  .section_padding {
    padding: 4rem 2rem;


  }
  .section_margin {
    margin: 4rem 2rem;
    
  }
  .overlay-image {
    display: none;
    position: absolute; /* Posiciona de forma absoluta em relação ao .gradient_bg */
    top: 10px; /* Ajuste conforme necessário */
    left: 50%; /* Ajuste conforme necessário */
    transform: translate(-50%, -50%); /* Centraliza a imagem */
  }
  
  .overlay-image img {
    width: -100px;
    opacity: 10; /* Define a transparência, ajuste conforme necessário */
  }

}

@media screen  and (max-width: 1050px) { 

  .gradient_bg {
    
  
    background-size: cover; /* Pode ser 'contain', 'cover', 'auto' ou um valor específico como '50% 50%' */
    background-position: -200px -20px; /* Pode ser 'top', 'bottom', 'left', 'right', 'center', ou coordenadas específicas como '50% 50%' */
    background-repeat: no-repeat; /* Pode ser 'repeat', 'repeat-x', 'repeat-y', ou 'no-repeat' */
    background-attachment: fixed; /* Pode ser 'scroll' ou 'fixed' */
    width: 75%;
    height: 100vh; /* Ajuste conforme necessário */


  
}
.overlay-image {
  position: absolute; /* Posiciona de forma absoluta em relação ao .gradient_bg */
  top: 40%; /* Ajuste conforme necessário */
  left: 50%; /* Ajuste conforme necessário */
  transform: translate(-50%, -50%); /* Centraliza a imagem */
}

.overlay-image img {
  width: 1000px;
  opacity: 10; /* Define a transparência, ajuste conforme necessário */
}



  
  
}


@media screen  and (max-width: 1200px) { 

  .gradient_bg {
    
  
    background-size: cover; /* Pode ser 'contain', 'cover', 'auto' ou um valor específico como '50% 50%' */
    background-position: -200px -20px; /* Pode ser 'top', 'bottom', 'left', 'right', 'center', ou coordenadas específicas como '50% 50%' */
    background-repeat: no-repeat; /* Pode ser 'repeat', 'repeat-x', 'repeat-y', ou 'no-repeat' */
    background-attachment: fixed; /* Pode ser 'scroll' ou 'fixed' */
    width: 75%;
    height: 100vh; /* Ajuste conforme necessário */


  
}
.overlay-image {
  position: absolute; /* Posiciona de forma absoluta em relação ao .gradient_bg */
  top: 27%; /* Ajuste conforme necessário */
  left: 50%; /* Ajuste conforme necessário */
  transform: translate(-50%, -50%); /* Centraliza a imagem */
}

.overlay-image img {
  width: 1000px;
  opacity: 10; /* Define a transparência, ajuste conforme necessário */
}



  
  
}