.meowl_upcoming {
    display: flex;
  flex-direction: column;
  color: #fff;
  font-family: var(--font-family);
  
  
}




.meowl_upcoming_container {
    display: flex;
    flex-direction: column;
    margin-left: 5rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.meowl_upcoming_container_card{
    background: #2f186e69;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 385px;
    width: 100%;
    height: 470px;
    display: flex;
    flex-direction: column;
}

.meowl_upcoming_background {
    position: relative;
    width: 100%;
    height: 350px; /* Ajuste a altura conforme necessário */

  }

.meowl_upcoming_background image {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }


.meowl_upcoming_status {
    font-family: var(--font-family);
    position: absolute;
    width: 103.5px;
    height: 25px;
    top: 10px;
    right: 5px;
    background-color: #27064F;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 15px;
    cursor: pointer;

}

.meowl_upcoming_logo {
    margin-top: -16.2rem; /* Ajuste conforme necessário */
    margin-left: 30px;
    z-index: 1;
}

.meowl_upcoming_logo img {
    width: 85px; /* Aumentando o tamanho da logo */
    height: auto;
}



.meowl_upcoming_content_first {
    position: relative;
    display: flex;
    margin-left: 2rem;
    justify-content: space-between;
    
    
}

.meowl_upcoming_content_title_midia h1 {
    font-size: 1rem;
    margin-top: 0.5rem;
}

.meowl_upcoming_content_media {
    display: flex;
    gap: 13px;
    color: rgba(255, 255, 255, 0.651);
    margin-top: 0.5rem;
    font-size: 1.5rem;
    position: relative; /* Garantir que os elementos internos não sejam sobrepostos */

}
.meowl_upcoming_content_built_container{
  position: relative;
}

.meowl_upcoming_content_built {
  margin-right: 1rem;
 
  left: 0rem;
    
}

.meowl_upcoming_content_built p{
    font-size: 0.67rem;
    font-weight: bold;
}

.meowl_upcoming_content_second {
    margin-top: 0.75rem;
    margin-left: 2rem;
}

.meowl_upcoming_content_navbar {
    display: flex;
    font-size: 0.8rem;
    gap: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
    position: relative; /* Garantir que os elementos internos não sejam sobrepostos */
}

.meowl_upcoming_content_data{
    justify-content: space-between;
}

.meowl_upcoming_content_data h3 {
    font-size: 0.75rem;
    margin-bottom: 1.4px;
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.534);
}

.meowl_upcoming_content_data p {
    font-size: 0.75rem;
}

.meowl_upcoming_content_button {
    position: relative; /* Posiciona o botão de forma absoluta em relação ao contêiner pai */
    bottom: 1rem; /* Define a distância do botão a partir da parte inferior do contêiner */
   
    width: 100%; /* Faz com que o botão ocupe toda a largura do contêiner *//* Ajuste o padding conforme necessário */
    text-align: center; /* Centraliza o texto dentro do botão */
    top: 0rem;
    right: 0.65rem;
  }

.meowl_upcoming_content_button button {
    margin-top: 1rem;
    width: 95%;
    height: 31px;
    background-color: #D862FF;
    color: rgb(255, 255, 255);
    font-weight: bold;
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
 
}

/**/.meowl_upcoming_content_navbar h3 {
    cursor: pointer;
    padding-bottom: 5px;
  }
  
  .meowl_upcoming_content_navbar h3.active {
    border-bottom: 2px solid #ffffff; /* Sublinhar a seção ativa */
  }

  .text-box-container {
    display: flex;
    gap: 10px;
    margin-bottom: 0.5rem;
    flex-wrap: wrap; /* Permite que as caixas quebrem linha se necessário */
  }
  
  .text-box {
    padding: 0.5rem 1rem;
    background-color: #D862FF;
    color: #fff;
    border-radius: 7px;
    font-weight: bold;
    font-size: 0.6rem;
    min-width: 20px; /* Tamanho mínimo da caixa */
    text-align: center; /* Centraliza o texto dentro da caixa */
    display: flex;
    align-items: center;
    justify-content: center;
    height: 10px;
  }
  
  .text-box h5 {
    margin: 0; /* Remover margens padrão */
    line-height: 1; /* Ajustar a altura da linha */
    padding-bottom: 2px; /* Adicionar margem interna inferior */
  }
  
  .text-box.box1 {
    background-color: #650773; /* Cor da primeira caixa */
  }
  
  .text-box.box2 {
    background-color: #370970; /* Cor da segunda caixa */
  }
  
  .text-box.box3 {
    background-color: #064a6f; /* Cor da terceira caixa */
  }
  .text-box-p {
    font-size: 0.85rem; /* Ajuste o tamanho da fonte conforme necessário */
    line-height: 1.3; /* Ajusta o espaçamento entre linhas */
    margin: 0;
    padding: 0;
    width: 100%; /* Faz o parágrafo ocupar toda a largura disponível */
    box-sizing: border-box;
    text-align: left; /* Alinha o texto à esquerda */
    width: 330px;
    font-weight: 400;
  }
  


 .light-mode .meowl_upcoming_content_navbar h3.active {
    border-bottom: 2px solid #000000;
 }

.light-mode .meowl_upcoming {

    background: #E7E4EF;
    color: #000000;
}

.light-mode .meowl_upcoming_container_card {
    background: #FFFFFF;

}

.light-mode .meowl_upcoming_content_media,
.light-mode .meowl_upcoming_content_data h3,
.light-mode .meowl_upcoming_content_button button {
    color: #000000;
}

@media screen and (max-width: 500px) {
  .meowl_upcoming_container {
    display: flex;
    flex-direction: column;
    margin-left: 2rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.meowl_upcoming_container_card{
  max-width: 385px;
  width: 95%;
  height: 470px;
 
}
.meowl_upcoming_content_navbar h3{
  font-size: 0.9rem;
}

}

@media screen and (max-width: 392px) {
  .meowl_upcoming_container {
    display: flex;
    flex-direction: column;
    margin-left: 1.4rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.meowl_upcoming_container_card{
  max-width: 385px;
  width: 85%;
  height: 485px;
 
}
.meowl_upcoming_content_navbar h3{
  font-size: 0.82rem;
}

}

@media screen and (max-width: 321px) {
  .meowl_upcoming_container {
    display: flex;
    flex-direction: column;
    margin-left: 1.4rem;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.meowl_upcoming_container_card{
  max-width: 385px;
  width: 72%;
  height: 495px;
 
}
.meowl_upcoming_content_navbar h3{
  font-size: 0.72rem;
}

}



      
